import Vue from 'vue'
import App from './App.vue'
import Route from 'vue-router';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import { loading } from "@/utils/elementUiLoading";

import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

//在main.js文件配置插件的功能
NProgress.configure({     
  easing: 'ease',  // 动画方式    
  speed: 500,  // 递增进度条的速度    
  showSpinner: false, // 是否显示加载ico    
  trickleSpeed: 200, // 自动递增间隔    
  minimum: 0.3 // 初始化时的最小百分比
})


Vue.config.productionTip = false
// 使用Vue.use来安装插件
Vue.use(Route);
Vue.use(ElementUI);
Vue.prototype.toLoading = loading;

// 定义一些路由
const routes = [
  // ... 路由配置
];
// 创建路由器实例
const router = new Route({
  mode: 'history', // 使用 HTML5 History 模式
  routes
});


//监听路由跳转，进入页面执行插件动画。
router.beforeEach((to, from , next) => {
  // 每次切换页面时，调用进度条
  NProgress.start();
  // 没有next()页面不会跳转的。这部分还不清楚的去翻一下官网就明白了
  next();
});

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')

//跳转结束了
router.afterEach(() => {  
  // 在即将进入新的页面组件前，关闭掉进度条
  NProgress.done()
})
